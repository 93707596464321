'use client';

import { ConfirmProvider } from 'material-ui-confirm';

import React from 'react';

const AppConfirmProvider = ({ children }: { children: React.ReactNode }) => {
  return (
    <ConfirmProvider
      useLegacyReturn
      defaultOptions={{
        confirmationButtonProps: { variant: 'contained', autoFocus: true },
        cancellationButtonProps: { variant: 'contained' },
        titleProps: { style: { padding: '1em', borderBottom: '1px solid rgba(0, 0, 0, 0.12)' } },
        contentProps: { style: { padding: '2em' } },
        dialogActionsProps: {
          style: { padding: '1em', borderTop: '1px solid rgba(0, 0, 0, 0.12)' },
        },
      }}
    >
      {children}
    </ConfirmProvider>
  );
};

export default AppConfirmProvider;

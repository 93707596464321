'use client';

import { datadogRum } from '@datadog/browser-rum';
import { useSession } from 'next-auth/react';
import { FC, ReactNode, createContext, useEffect } from 'react';
import { trpc } from 'trpc/client';
import useToggle from 'hooks/useToggle';
import { AppointmentType, DocumentType } from '@cp/database/types';

type ContextProps = {
  user?: any;
  appointmentTypes?: AppointmentType[];
  documentTypes?: DocumentType[];
  payers?: any;
  drawerOpen?: boolean;

  setDrawerToggle?: () => void;
};

const AppContext = createContext<ContextProps>({});

type Props = {
  children: ReactNode;
};

const AppProvider: FC<Props> = ({ children }) => {
  const { status } = useSession();
  const [drawerOpen, setDrawerToggle] = useToggle(true);
  const { data } = trpc.user.get.useQuery(undefined, {
    enabled: status === 'authenticated',
  });
  const { data: appointmentTypes } = trpc.appointments.getTypes.useQuery(undefined, {
    enabled: status === 'authenticated',
    initialData: [],
  });
  const { data: documentTypes } = trpc.documents.getTypes.useQuery(undefined, {
    enabled: status === 'authenticated',
    initialData: [],
  });
  const { data: payers } = trpc.insurance.getPayers.useQuery(undefined, {
    enabled: status === 'authenticated',
  });

  useEffect(() => {
    datadogRum.setUser({
      id: data?.user?.id,
    });
  }, [data?.user?.id]);

  return (
    <AppContext.Provider
      value={{
        user: data?.user,
        appointmentTypes,
        documentTypes,
        payers,
        drawerOpen,
        setDrawerToggle,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export { AppContext, AppProvider };
